<template>
  <div class="guide">
    <div class="video-content">
      <div class="video-mask">
        <video controls id="video" autoplay>
          <source src="" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import setting from '@/api/setting';

export default {
  name: 'index',
  mounted() {
    this.video = document.querySelector('#video');
    setting.get({
      type: 1,
    }).then(({ data }) => {
      // this.video.src = `http://47.94.225.94${data}`;
      this.video.src = `${window.location.origin}${data}`;
      console.log(data);
    });
  },
};
</script>

<style scoped lang="less">
  .guide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .video-content {
      width: 80%;
      .video-mask {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
        video {
          position: absolute;
          width: 100%;
          height: 100%;
          &:focus {
            outline: transparent;
          }
        }
      }
    }
  }
</style>
