
import { API_URL } from '@/constants/';
import axios from './axios';

export default {
  get(data) {
    return axios.get(`${API_URL}/settings`, {
      params: data,
    }).then((res) => res.data);
  },
};
